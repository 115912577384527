import { Component, OnInit } from '@angular/core';
import { SwaggerUIBundle, SwaggerUIStandalonePreset } from "swagger-ui-dist"

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  standalone: true
})

export class AppComponent implements OnInit {
  ngOnInit() {
    SwaggerUIBundle({
      urls: [
        {
          name: 'v1',
          url: 'assets/docinteract-api-v1.yml'
        }
        // {
        //   name: 'v0',
        //   url: 'assets/docinteract-api-v0.yml'
        // }
      ],
      domNode: document.getElementById('swagger-ui'),
      deepLinking: true,
      presets: [SwaggerUIBundle['presets'].apis, SwaggerUIStandalonePreset],
      layout: 'StandaloneLayout',
      requestInterceptor: (req) => { 
        const token = req['headers']['Authorization']
        req['headers']['doc-interact-token'] = token
        return req; 
      },
    });
  }
}
